:root {
  --azul: #2f80ed;
  --rojobase: #cc0819;
  --verde: #81bc00;
  --grisclaro: #f4f4f4;
  --gris: #b4b4b4;
  --negrobase: #282828;
  --blanco: #ffffff;
  --azul2: #2574eb;
  --gris2: #828282;
}
html {
  font-size: 62.5%;
  box-sizing: border-box;
  margin-top: 0 !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
h1,
h2,
h3 {
  margin: 0 0 2rem 0;
  line-height: 1.5;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}

