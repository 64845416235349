.login-contenedor-principal {
  display: flex;
  justify-content: center;
  background: #e5e5e5;
  height: 100vh;
  align-items: center;
  margin: 0 auto;
  background: url('https://images.unsplash.com/1/work-station-straight-on-view.jpg?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80');
  background-size:cover;
  background-position:center;
  box-shadow:inset 0 0 0 2000px rgba(255, 255, 255, 0.8);

  /* @media (min-width: 360px) {
    display: flex;
    justify-content: center;
  } */
}
.login-contenedor-login {
  width: 27.77777777777778vw;
  background: #ffffff;
  
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  
  border-top: 15px solid #F42434;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 02vw !important;
  padding-bottom: 3vw;
}

h3{
  margin:0px;
}

#separador{
margin-left:7vw;
width:8vw;
}
.login-contenedor.iniciosesion {
  font-size: 1.4583333333333333vw;
  text-align: center;
  line-height: 1.476;
  font-weight: 800;
  font-family: "Roboto";
  padding-bottom: 2.3944444444444444vh;
  /* padding-top: 1.5527777777777778vh; */
}
.login-input {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.25vw;
  font-family: Roboto;
  line-height: 1.444;
  color: var(--gris2);
  width: 95%;
  border:none;
  
}

.login-input {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.25vw;
  font-family: Roboto;
  line-height: 1.444;
  color: var(--gris2);
  width: 95%;
  border:none;
  
}

.login-input:focus {
  outline:none;
  
}


.login-input-boton {
  font-size: 1.4583333333333333vw;
  font-family: Roboto;
  line-height: 1.476;
  width: 17.63888888888889vw;
  color: var(--blanco);
  background-color: var(--azul);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.35);
  margin-bottom:3vw;
  margin-top:3vw;
}

button, html input[type=button], input[type=reset], input[type=submit]{
  border-radius: 4px;
  border: 0;
  background-color:#F42434;
  color:white;
  padding-top:0.5vw;
  padding-bottom:1vw;
  width:15vw;
  margin-top:2vw;
}

#logo{
  
    position: absolute;
    right: 2vw;
    width: 20%;
    bottom: 5vh;

}

.row {
 margin-right:1.5vw;
 margin-left:1.5vw;
  border-bottom: 1px solid #F42434;
  margin-top: -2vw;
}

h3{
  text-align:center;
}

.col-md-1 {
  width: 8.33333333%;
  float: right;
  top: 2vw;
  right:1vw;
  
}

.col-md-11 {
  padding: 0px;
}

input:focus{
  border:none;
}

.css-1izdak4 {
  font-size: 0.9722222222222222vw;
  font-family: "Roboto";
  line-height: 1.429;
  padding-left: 1.5vw !important;
}

/*MOVIL*/

@media (max-width: 600px){
  .login-contenedor-login{
    width:300px;
    padding-left:20px;
    padding-right:20px;
  }

  .css-1izdak4{
    font-size:16px !important;
  }

  .login-input{
    font-size:16px;
    background-color: transparent;
  }

  #separador {
    margin-left: 35%;
    width: 70px;
    margin-bottom: 30px;
}

.col-md-1 {
  width: 8.33333333%;
  float: right;
  top: 25px;
  right: 10px;
}

button, html input[type=button], input[type=reset], input[type=submit] {
  border-radius: 4px;
  border: 0;
  background-color: #F42434;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 150px;
  margin-top: 2vw;
}

#logo {
  position: absolute;
  width: 70%;
  top: 70px;
  left: auto;
  right: auto;
}
}

/* TABLET */

@media (max-width: 1024px) and (min-width:601px){
  .login-contenedor-login{
    width:500px;
    padding-left:40px;
    padding-right:40px;
  }

  .css-1izdak4{
    font-size:20px !important;
  }

  .login-input{
    font-size:24px;
    background-color: transparent;
    margin-top:20px;
  }

  #separador {
    margin-left: 38%;
    width: 90px;
    margin-bottom: 30px;
}

.col-md-1 {
  width: 8.33333333%;
  float: right;
  top: 20px;
  right: 10px;
}

button, html input[type=button], input[type=reset], input[type=submit] {
  border-radius: 4px;
  border: 0;
  background-color: #F42434;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  margin-top: 2vw;
}

#logo {
  position: absolute;
  width: 50%;
  top: 190px;
  left: auto;
  right: auto;
}

h3{
  font-size:28px;
}
}