#buscar-titulo{
    width:43vw !important;
}

#fechas-filtro{
    width:100%;
}

.sucursal{
    background-color: #F42433;
    padding:1vw;
    font-size:1.5vw;
    color:white;
    margin:0px;
    width:100%;
 
    
}

.eventos{
    background-color:white;
    width:45%;
    display:inline-block;
    border-radius:0.5vw;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
    text-align:center;
   
}

.col-md-6{
    padding:0px !important;
    width: 100% !important;
}

.eventos .col, .eventos2 .col{
    width:50%;
    display:inline-block;
    vertical-align: middle;
    text-align:left;
    padding-top:1vw;
}

.eventos2{
    background-color:white;
    width:45%;
    display:inline-block;
   float: right;
   border-radius:0.5vw;
   box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
   
}

h4{
    border-radius:0.5vw;
}


#buscar-evento{
    width:47vw;
}

#buscarevento{
    width:43vw;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#81BC00;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:22px;
}

#calendario{
    text-align:center;
    justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  vertical-align:middle;
  width:50%;
}

#info-evento .col{
    align-items: center;
    vertical-align: middle;
    
}

#info-evento{
    margin-top: 10vw;
    margin-bottom: 3vw;
    
}

.close-modal-btn {
    color: white;
    cursor: pointer;
    float: left;
    font-size: 2vw !important;
    

}
.modal-header {

    background-color: #F42433;
    color: white;
}

.info-modal {
    width: 50%;
    display: inline-block;
    
}

.modal-body {
    position: relative;
    padding-left: 4vw;
    padding-top:3vw;
}

.info-modal input{
    font-size:1.5vw;
    margin-bottom:1vw;
    width: 88%;
}

.info-modal h3{
    text-align:left;
    margin-bottom:0.7vw;
}

#edita-evento{
    background-color:#626262;
    width:13vw;
    margin-top:0vw !important;
}

.open-modal-btn{
    background-color: #2F80ED;
    width:13vw;
    margin-left: 1vw;
    margin-bottom: 1vw;
    margin-top:0.5vw;
}

#agenda-fechas label{
font-weight:normal;
}

@media (max-width:600px){
    .layout-main{
        background-color:white;
    }

    .Filtros{
        border:none;
        box-shadow: none;
    }

    .col-md-6 label {
     
        font-size: 20px;
    }

    .form-select {
    height:30px;
      
        font-size: 18px;
    }
}