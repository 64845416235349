.big-image {
  height: 100vh;
  width:100vw;
  position: relative;
 overflow-x: hidden;
 overflow-y:hidden;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: url('https://andattigourmet.com/wp-content/uploads/2022/01/fondo-tickets-1.jpg');
  /*   background: rgba(0, 0, 0, 0.65); */
  color: white;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
}

.v3theme-fixed-container.banner .logo {
  
  height: auto !important;
  max-width: 100%;
  width: 30vw !important;
  margin-right: 53vw !important;
}

#elemento{
  background-color:red !important;
}

.smcx-embed>.smcx-iframe-container {
  width: 100%;
  max-width: 100% !important;
  height: 665px;
}

.makeStyles-root-1 {
  width: 50%;
  border: 0px solid #999 !important;
  margin: 6rem auto;
}

div.v3theme-fixed-container.banner {
  display: block;
  position: relative;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 100;
  overflow-x: hidden;
  background-color: white !important;
  overflow-y: hidden;
}

div.v3theme-fixed-container.banner .v3theme-fixed-container-child {
    padding: 40px 40px;
    min-height: 40px;
    width: 25vw !important;
    padding-bottom: 0px;
    background-color: white;
}

.survey-page-body{
  padding: 5vw !important;
}
            
