
.Filtros {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.20);
  padding: 2;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 0.5vw;
  border-top: 15px solid #FCB503;
  background-color: white;
}


#cant{
  width:4vw;
  margin-left:1vw;
}

#orden{
  width:10vw;
  margin-left:1vw;

text-align:right;
}

#root > div > div > main > div.Informacion > div:nth-child(2){
  text-align:right;
}



  .info input{
    width:20vw;
    height:3vw;
  }
  
  .info{
    margin-top:1vw;
  }

  .col-md-6 label{
      margin-top:2vw;
      font-size:1.5vw;
  }

  
  #inicio{
    width: 10vw !important;
    display: block;
   
}

#final{
    width: 9.4vw !important;
    display: block;

}

#root > div > div > main > div > div.info > div:nth-child(4) > div.row.g-3{
    display:block;
    margin:0px !important;
}

#fechas {
    width: 16%;
}

.Informacion {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.20);
    padding: 4rem;
    border-radius: 0.5vw;
   
    background-color:white;
  }

#detalles{
background: #FFFFFF;

border: 1px solid #2574EB;
box-sizing: border-box;
border-radius: 4px;
color: #2574EB;
width:5vw;
margin:0px;
padding:0.5vw;
  }