li {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: #81bc00;
}
li > a {
  color: white;
  padding: 0.5rem;
}
ul {
  display: flex;
  justify-content: center;
}
