.formulario-contenedor {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
  padding-top: 6rem !important;

  border-radius: 0.5vw;
  border-top: 30px solid #fcb503 !important;
  margin-top: 20px !important;
  background-color: white;
  height: 900px;
}

.formulario-contenedor-botones {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  border-radius: 0.5vw;
  border-top: 30px solid #F42433;
  background-color: white;
}

#addsbuttons button {
  width: 100%;
  padding: 10%;
  background-color: #81bc00;
}
#addsbuttons .col-md-2 {
  float: none;
  top: 0%;
  right: 0%;
  display: inline-block;
}

#tablaForm {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#tablaForm td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#tablaForm tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tablaForm tr:hover {
  background-color: #ddd;
}

#tablaForm th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #81bc00;
  color: white;
}



.addIframe form input,
input[type='radio'] + label,
input[type='checkbox'] + label:before,
select option,
select {
  width: 100%;
  padding: 1em;
  line-height: 1.4;
  background-color: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.addIframe input:focus {
  outline: 0;
  border-color: #bd8200;
}
.addIframe input:focus + .input-icon i {
  color: #f0a500;
}
.addIframe input:focus + .input-icon:after {
  border-right-color: #f0a500;
}
.addIframe input[type='radio'] {
  display: none;
}
.addIframe input[type='radio'] + label,
select {
  display: inline-block;
  width: 50%;
  text-align: center;
  float: left;
  border-radius: 0;
}
.addIframe input[type='radio'] + label:first-of-type {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.addIframe input[type='radio'] + label:last-of-type {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.addIframe input[type='radio'] + label i {
  padding-right: 0.4em;
}
.addIframe input[type='radio']:checked + label,
input:checked + label:before,
select:focus,
select:active {
  background-color: #f0a500;
  color: #fff;
  border-color: #bd8200;
}
.addIframe select,
option {
  color: #000;
}
.addIframe input[type='checkbox'] {
  display: none;
}
.addIframe input[type='checkbox'] + label {
  position: relative;
  display: block;
  padding-left: 1.6em;
}
.addIframe input[type='checkbox'] + label:before {
  position: absolute;
  top: 0.2em;
  left: 0;
  display: block;
  width: 1em;
  height: 1em;
  padding: 0;
  content: '';
}
.addIframe input[type='checkbox'] + label:after {
  position: absolute;
  top: 0.45em;
  left: 0.2em;
  font-size: 0.8em;
  color: #fff;
  opacity: 0;
  font-family: FontAwesome;
  content: '\f00c';
}
.addIframe input:checked + label:after {
  opacity: 1;
}
.addIframe select {
  height: 3.4em;
  line-height: 2;
}
.addIframe select:first-of-type {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.addIframe select:last-of-type {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.addIframe select:focus,
select:active {
  outline: 0;
}
.addIframe select option {
  background-color: #f0a500;
  color: #fff;
}
.addIframe .input-group {
  margin-bottom: 1em;
  zoom: 1;
}
.addIframe .input-group:before,
.input-group:after {
  content: '';
  display: table;
}
.addIframe .input-group:after {
  clear: both;
}
.addIframe .input-group-icon {
  position: relative;
}
.addIframe .input-group-icon input {
  padding-left: 4.4em;
}
.addIframe .input-group-icon .input-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4em;
  text-align: center;
  pointer-events: none;
}
.addIframe .input-group-icon .input-icon:after {
  position: absolute;
  top: 0.6em;
  bottom: 0.6em;
  left: 3.4em;
  display: block;
  border-right: 1px solid #e5e5e5;
  content: '';
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.addIframe .input-group-icon .input-icon i {
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.addIframe .container {
  max-width: 38em;
  padding: 1em 3em 2em 3em;
  margin: 0em auto;
  background-color: #fff;
  border-radius: 4.2px;
  box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2);
}
.addIframe .row {
  zoom: 1;
}
.addIframe .row:before,
.row:after {
  content: '';
  display: table;
}
.addIframe .row:after {
  clear: both;
}
.addIframe .col-half {
  padding-right: 10px;
  float: left;
  width: 50%;
}
.addIframe .col-half:last-of-type {
  padding-right: 0;
}
.addIframe .col-third {
  padding-right: 10px;
  float: left;
  width: 33.33333333%;
}
.addIframe .col-third:last-of-type {
  padding-right: 0;
}
@media only screen and (max-width: 540px) {
  .addIframe .col-half {
    width: 100%;
    padding-right: 0;
  }
}
.freebirdFormviewerViewFooterEmbeddedBackground {
  display: none !important;
}


#tdButtons button{
  width: 40px;
  margin-right: 10px;
  padding: 10px 10px;
  font-size: 15px;
  margin-top: 0px;
}

#tablaForm tbody{
  text-align: center;
}

#tablaForm thead th{
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}


/* 12/06/21 nolan */
#newForm{
  padding-top:2vw;
}

.react-datepicker__input-container{
  border: 1px solid #CCCCCC;

  /*box-shadow: 0px 12px 1px -11px rgba(204, 204, 204,0.55) inset;*/
  padding:0.3vw;
  border-radius:4px;
}

#vigenciaEncuestaFin, #vigenciaEncuestaInicio, #vigenciaBeneficio{
  width:100%;
  border: 0px;
  padding:0px;
}

#vigenciaEncuestaFin:focus, #vigenciaEncuestaInicio:focus, #vigenciaBeneficio:focus{
  width:100%;
  border: 0px;
  padding:0px;
  outline:none;
  padding-top:2px;
  padding-bottom:2px;
}

select option{
  text-align:left;
}

.form-control {
 
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.0);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 0%);
 
}

/*visualizar encuesta*/

.formulario-contenedor::-webkit-scrollbar {
  background-color:red;              /* width of the entire scrollbar */
}

.v3theme .survey-page .survey-page-body {
  padding: 20px 00px 0 10px;
}

.survey-page .survey-page-body {
  max-width: 68%;
  margin: auto;
  border: 0;
}

#patas > main > article > header > div > div > a{
 
  background-color: #F42433 !important;
  padding: 0.5vw 2vw 2vw 0.5vw !important;
  color: white !important;
}



.survey-page .survey-page-header {
  position: relative;
  margin-left: 13vw !important;
  margin-right: 13vw !important;
}

#newForm > div:nth-child(5) > div.col-md-12 > div > fieldset{
  padding:0px !important;
}

#tablaForm thead th {
 
  padding-top: 5px;
  padding-bottom: 5px;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 3px dashed #EFEFEF;
}

#newForm > div:nth-child(6){
 
  margin-left: 40px;
  margin-right: 40px;
}



.form-control {

  height: 38px;
 
}

.form-group {
  margin-bottom: 39px;
}

#newForm > div:nth-child(5){
  padding-left:15px;
  padding-right:15px;
}

#newForm > div:nth-child(4) > div.col-md-12 > div > fieldset{
  padding-left:0;
  padding-right:0;
}




.form-control:focus {
  
 
  box-shadow: white;
  outline:none !important;
}

.form-control:focus {
  border:2px solid #59A1FF;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#newForm > div:nth-child(1) > div.col-md-2 > div{
  float:right;
}

#beneficios .col-md-4 {
 
  height: 7vw;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: 7px;
}

/*beneficios*/
#beneficios{
margin-top:0px;
padding-top:0px;
margin-bottom: 31px;
}

#newForm > div:nth-child(2) > div:nth-child(1) > div > div, #promoqr > div > div > div{
  width: 10vw;
}

#cantBeneficio, #descBeneficio, #monedero, #descuento{
width: auto !important;
}

#beneficios > div.form-group{
  margin-bottom:0px !important;
}

.row {
  margin-right: 1.5vw;
  margin-left: 1.5vw;
  border-bottom: 1px solid #F42434;
  margin-top: 0vw !important;
}

#promoqr {
  top: 0px;
  margin-top: -30px;
}


/* MODAL */
.modal-footer {
  background: white;
  height: 80px;
  padding: 15px;
  border: 1px solid white;
  border-top: 1px solid gray;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-body {
  position: relative;
  padding-left: 3vw;
  padding-top: 3vw;
  height: 150px;
  font-size: 20px;
  color: black;
  margin-bottom:1vw;
}

#fade-flip > div > div.modal-footer > button{
  margin-top:0px;
  padding-top:1vw !important;
  width: 10vw;
}

.modal-wrapper{
  width: 35% !important;
  top: 24% !important;
  right: 25vw !important;
  border-radius:10px
}

#fade-flip > div > div.modal-header > h3{
  margin-top: 0.5vw;
}

.modal-header {

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
