.TitFolio{
    font-size:1vw;
}

.datoFolio{
    font-size:1.2vw;
}

.preguntaFolio{
    font-weight:bold;
    font-size:1.2vw;

}

.descFolio{
    font-size:1.2vw;
    margin-bottom:1vw;
    
}


.seguimiento {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.20);
    padding: 2;
    padding-top: 2rem;
    padding-bottom: 3rem;
    border-radius: 0.5vw;
    border-top: 15px solid #81BC00;
    background-color: white;

  }

  .form-select{
    width: 300px;
    height: 2.5vw;
    left: 298.71px;
    top: 591.5px;
    
    background: #FFFFFF;
    /* Gris - */
    
    border: 1px solid #B4B4B4;
    box-sizing: border-box;
    border-radius: 4px;
    font-size:1.2vw;
   
  }

  #areaSeg{
      margin-right:2vw;
  }

  #comentario{
  
        width: 45.2vw;
  
  }

  #actualizar{
      float:right;
      width:15vw;
  }