
.entrevista-contenedor {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.20);
  padding:0px !important;
  padding-top:6rem !important;

  border-radius: 0.5vw;
  border-top: 15px solid #FCB503;
  background-color:white;
}

#buscaremp{
  margin-top:0px;
  margin-bottom:2vw;
}

.header-contenedor{
  background-color: #CC0819;
  border:none;
}

.header-nav{
  margin-right:2vw;
  color:white;
}
.header-nav a{
  margin-left:2vw;
  color:#FCB503;

}

.layout-seccion-principal {

  background-color: #F2F2F2;
}

.entrevista-contenedor-buscador {
  text-align: end;
 
}
.entrevista-input-buscar {
  width: 40%;
  border-radius:0.2vw;
  margin-top:0px;
}
.entrevista-contenedor-boton {
  margin: 2rem;
  text-align: end;
  margin-top:2vw;
}
.entrevista-boton {
  font-size: 1.094vw;
  padding: 0.8vw;
  color:white;
width: 211.23px;
height: 40px;
left: 50vw;
top: 452px;
background: #2F80ED;
box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
border-radius: 4px;
}

.entrevista-boton:hover {
  color:white;
  text-decoration: none;
}

.entrevista-boton:visited {
  color:white;
  text-decoration: none;
}

#noempleado{
  border-radius:0.3vw 0vw 0vw 0.3vw;
  border:1px solid #C9C9C9;
  width:15vw;
  height:2.5vw;
  
}

.entrevista-contenedor-buscador button{
  border-radius:0vw 0.3vw 0.3vw 0vw;
  border:0.15vw solid #F42434;
  height:2.5vw;
  background-color: #F42434;
  color:white;
  width:5vw;
  margin-top:0px;
}

.info input{
  width:20vw;
  height:3vw;
}

.info{
  margin-top:4vw;
}

.entrevista{
  margin-top:4vw;
  padding-bottom:2vw;
}

.entrevista textarea{
  width:100%;
  height:7vw;
  margin-bottom:2vw;
}

.entrevista textarea:focus{
  border: solid 2px #81BC00;
}

#opciones{
  text-align:center;
}

.sidebar-opcion:hover {
  text-decoration: none !important;

}

.row{
  border-bottom:none;
}

.entrevista-contenedor {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.20);
  padding: 4rem;
  border-radius: 0.5vw;
  border-top: 15px solid #FCB503;
  background-color: white;
  padding-top: 5vw;
}


/*TABLET*/

@media (max-width:1024px) and (min-width: 601px){
aside{
  flex-basis: 0px;
}

.row {
  margin-right: 0vw;
  margin-left: 0vw;
  border-bottom: 0px solid #F42434;
  margin-top: 0vw;
}

.entrevista-contenedor-buscador button {
  border-radius: 0vw 0.3vw 0.3vw 0vw;
  border: none;
  background-color: #F42434;
  color: white;
  height:3.5vw;
  width: 5vw;
}

#noempleado {
  border-radius: 0.3vw 0vw 0vw 0.3vw;
  border: 1px solid #C9C9C9;
  width: 15vw;
  height: 3.5vw;
}

.entrevista-contenedor-buscador {
  text-align: end;
}

.info{
  margin-bottom:50px;
  margin-top:20px;
}

.entrevista-boton {
  font-size: 16px;
  padding: 10px;
  
}

.entrevista-contenedor-buscador button {
width:70px;
  margin-top: 0px;
}

.sidebar-opcion {
  font-family: "Roboto";
  font-size: 20px;

}
}

/*MOVIL*/

@media (max-width:600px){
  .sidebar-label {
    margin-bottom: 0;
    padding-left: 1vw;
    display: none;
}

aside {
  background-color: var(--rojobase);
  flex: 0 0 0px;
  width: 50px;
}

.sidebar-opcion {

  padding-left: 24%;
}


.header-nav a {
  margin-left: 15vw;
  color: #FCB503;
}

.layout-main {
  padding: 20px;
}

#noempleado {
  border-radius: 0.3vw 0vw 0vw 0.3vw;
  border: 1px solid #C9C9C9;
  width: 70%;
  height: 35px;
}

.entrevista-contenedor-buscador button {
  border-radius: 0vw 0.3vw 0.3vw 0vw;
  border: 1px solid #F42434;
  height: 2.5vw;
  background-color: #F42434;
  color: white;
  width: 5vw;
  margin-top: 0px;
  width: 30%;
  height: 35px;
  padding:0px;
}

.entrevista-contenedor {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.20);
  /* padding: 4rem; */
  border-radius: 0.5vw;
  border-top: 15px solid #FCB503;
  background-color: white;
  
  margin: 0px;
  padding: 0px;
  padding-top: 5vw;
}

.info input{
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  width: 100% !important;
  margin: 0;
  height:30px;
 
}

.info{
  margin-bottom:30px;
}

.entrevista-boton {
  font-size: 16px;
  padding: 10px;
  
}

.entrevista textarea{
  height:80px;
}

#opciones{
  display:none;
}
.entrevista-contenedor-boton {
  margin: 2rem;
  text-align: center;
  margin-top: 30px;
}

.entrevista-contenedor-boton {
  margin: 2rem;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom:30px;
}

.entrevista-boton {
  font-size: 16px;
  padding: 10px;
  padding-left: 35px;
  padding-right: 35px;
}

.layout-main {
  padding: 00px;
}
}