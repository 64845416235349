.login-contenedor-principal-encuesta {
    display: flex;
    justify-content: center;
    background: #e5e5e5;
    height: 100vh;
    align-items: center;
    margin: 0 auto;
    background: url('./fondo.png');
    background-size:cover;
    background-position:center;
    box-shadow:inset 0 0 0 2000px rgba(255, 255, 255, 0.4);
  
    /* @media (min-width: 360px) {
      display: flex;
      justify-content: center;
    } */
  }
  
  .login-contenedor-principal-encuesta .login-contenedor-login {
    width: 27.77777777777778vw;
    background:none;
    box-shadow: none;

    border-radius: 4px;
    
    border-top:none;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 02vw !important;
    padding-bottom: 3vw;
  }
  
  .login-contenedor-principal-encuesta  h3{
    margin:0px;
  }
  
  .login-contenedor-principal-encuesta  #separador{
  /*margin-left:7vw;*/
    width: 12vw;
    /*justify-content: center !important;*/
    margin-left: 5vw !important;
    padding-top: 0px;
      
  }

  .login-contenedor-principal-encuesta .box{
    margin-top: 15px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .login-contenedor-principal-encuesta    .box h1{
        text-transform: uppercase;
        font-weight: 500;
        font-size: 19px;
    }

    .login-contenedor-principal-encuesta    .box input[type='text']{
        background: none;
        display: block;
        margin: 20px auto;
        text-align: center;
        border: 2px solid #6c757d;
        padding: 14px 10px;
        width: 200px;
        outline: none;
        border-radius: 5px;
        transition: 0.25s;
    }

    .login-contenedor-principal-encuesta   .box input[type='submit']{
        background: none;
        display: block;
        background: #81bc00;
        color: white;
        margin: 20px auto;
        text-align: center !important;
        border: 2px solid #6c757d !important;
        padding: 14px 40px !important;
        outline: none !important;
        border-radius: 5px !important;
        transition: 0.25s !important;
        cursor: pointer !important;
    }

    .login-contenedor-principal-encuesta   .box input[type='text']:focus{
    width: 280px !important;
    border-color: #cc0819 !important;
    }

    .login-contenedor-principal-encuesta .login-contenedor.iniciosesion {
    font-size: 1.4583333333333333vw;
    text-align: center;
    line-height: 1.476;
    font-weight: 800;
    font-family: "Roboto";
    padding-bottom: 2.3944444444444444vh;
    /* padding-top: 1.5527777777777778vh; */
  }
  .login-contenedor-principal-encuesta  .login-input {
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.25vw;
    font-family: Roboto;
    line-height: 1.444;
    color: var(--gris2);
    width: 95%;
    border:none;
    
  }
  
  .login-contenedor-principal-encuesta  .login-input {
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.25vw;
    font-family: Roboto;
    line-height: 1.444;
    color: var(--gris2);
    width: 95%;
    border:none;
    
  }
  
  .login-contenedor-principal-encuesta  .login-input:focus {
    outline:none;
    
  }
  
  
  .login-contenedor-principal-encuesta  .login-input-boton {
    font-size: 1.4583333333333333vw;
    font-family: Roboto;
    line-height: 1.476;
    width: 17.63888888888889vw;
    color: var(--blanco);
    background-color: var(--azul);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.35);
    margin-bottom:3vw;
    margin-top:3vw;
  }
  
  .login-contenedor-principal-encuesta button, html input[type=button], input[type=reset], input[type=submit]{
    border-radius: 4px;
    border: 2px solid #6c757d !important;
    background-color:#81bc00;
    color:white;
    /*padding-top:0.5vw;*/
    /*padding-bottom:1vw;*/
    
    /*margin-top:2vw;*/
    width: 127px;
    height: 56px;
    /*text-align: center !important;*/
    padding:0px;

  }
  
  .login-contenedor-principal-encuesta  #logo{
    
      position: absolute;
      right: 2vw;
      width: 20%;
      bottom: 5vh;
  
  }
  
  /*.row {
   margin-right:1.5vw;
   margin-left:1.5vw;
    border-bottom: 1px solid #F42434;
    margin-top: -2vw;
  }*/
  
  .login-contenedor-principal-encuesta  h3,p,h1{
    text-align:center;
  }

  .login-contenedor-principal-encuesta  h1{
      font-size: 35px;
      font-weight: 500;
      margin-bottom: 0px !important;
     
  }

  .login-contenedor-principal-encuesta  p{
      font-size: 26px;
      font-weight: 400;
      font-family: Sans-serif;
  }
  



  .login-contenedor-principal-encuesta .col-md-1 {
    width: 8.33333333%;
    float: right;
    top: 2vw;
    right:1vw;
    
  }
  
  .login-contenedor-principal-encuesta  .col-md-11 {
    padding: 0px;
  }
  
  .login-contenedor-principal-encuesta  input:focus{
    border:none;
  }
  
  .login-contenedor-principal-encuesta  .css-1izdak4 {
    font-size: 0.9722222222222222vw;
    font-family: "Roboto";
    line-height: 1.429;
    padding-left: 1.5vw !important;
  }
  
  /*MOVIL*/
  
  @media (max-width: 600px){
    .login-contenedor-principal-encuesta    .login-contenedor-login{
      width:300px;
      padding-left:20px;
      padding-right:20px;
    }
  
    .login-contenedor-principal-encuesta   .css-1izdak4{
      font-size:16px !important;
    }
  
    .login-contenedor-principal-encuesta  .login-input{
      font-size:16px;
      background-color: transparent;
    }
  
    .login-contenedor-principal-encuesta  #separador {
      margin-left: 35%;
      width: 70px;
      margin-bottom: 30px;
  }
  
  .login-contenedor-principal-encuesta  .col-md-1 {
    width: 8.33333333%;
    float: right;
    top: 25px;
    right: 10px;
  }
  
  .login-contenedor-principal-encuesta  button, html input[type=button], input[type=reset], input[type=submit] {
    border-radius: 4px;
    border: 0;
    /*background-color: #F42434;*/
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 150px;
    margin-top: 2vw;
  }
  
  .login-contenedor-principal-encuesta  #logo {
    position: absolute;
    width: 70%;
    top: 70px;
    left: auto;
    right: auto;
  }
  }
  
  /* TABLET */
  
  @media (max-width: 1024px) and (min-width:601px){
    .login-contenedor-principal-encuesta  .login-contenedor-login{
      width:500px;
      padding-left:40px;
      padding-right:40px;
    }
  
    .login-contenedor-principal-encuesta  .css-1izdak4{
      font-size:20px !important;
    }
  
    .login-contenedor-principal-encuesta  .login-input{
      font-size:24px;
      background-color: transparent;
      margin-top:20px;
    }
  
    .login-contenedor-principal-encuesta  #separador {
      margin-left: 38%;
      width: 90px;
      margin-bottom: 30px;
  }
  
  .login-contenedor-principal-encuesta  .col-md-1 {
    width: 8.33333333%;
    float: right;
    top: 20px;
    right: 10px;
  }
  
  .login-contenedor-principal-encuesta  button, html input[type=button], input[type=reset], input[type=submit] {
    border-radius: 4px;
    border: 0;
    /*background-color: #F42434;*/
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 250px;
    margin-top: 2vw;
  }
  
  .login-contenedor-principal-encuesta  #logo {
    position: absolute;
    width: 50%;
    top: 190px;
    left: auto;
    right: auto;
  }
  
  .login-contenedor-principal-encuesta  h3{
    font-size:28px;
  }
  }