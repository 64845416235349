.header-contenedor {
  display: flex;
  justify-content: space-between;
  background-color: #F42433 !important;
  padding: 1.3rem;
  align-items: center;
}
.header-nav {
  display: flex;
  align-items: center;
}
.layout-contenedor-principal {
  display: flex;
  min-height: 100vh;
}
.layout-seccion-principal {
  flex: 1;
}
.layout-main {
  padding: 4rem;
}
aside {
  background-color: #282828;
  flex: 0 0 300px;
}

.material-icons{
color:#F42433;
}

#logoBar{
  margin-top:1vw;
  width: 70%;
}

.sidebar-titulo {
  font-family: Roboto;
  font-size: 1.5277777777777777vw;
  font-weight: 500;
  color: var(--blanco);
  padding: 2rem;
  
  text-align:center;
}
.sidebar-contenedor-opciones {
  padding: 0rem;
}

.sidebar-opcion {
  font-family: "Roboto";
  font-size: 1.25vw;
  color: #fff;
  line-height: 1.444;
  padding: 2rem;
  display: flex;
  align-items: center;
}
.sidebar-opcion:hover {
  background-color: var(--blanco);
  color: #F42433;
  
 
}
.sidebar-opcion:active {
  background-color: var(--blanco);
  color: #F42433;

}
.sidebar-contenedor-opcion {
  font-size: 1.25vw;
  margin-right: 1vw;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--blanco);
  /* &:hover {
    color: var(--rojobase);
  }
  &:active {
    background-color: var(--blanco);
    color: var(--rojobase);
  } */
}
.sidebar-label {
  margin-bottom: 0;
  padding-left: 1vw;
}

